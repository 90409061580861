body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "TTNormsPro";
  src: url("assets/fonts/TT Norms Pro Regular.woff") format("woff");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: italic;
  font-weight: 400; /* normal */
  src: url("assets/fonts/TT Norms Pro Italic.woff") format("woff");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: normal;
  font-weight: 100;
  src: url("assets/fonts/TT Norms Pro Thin.woff") format("woff");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: italic;
  font-weight: 100;
  src: url("assets/fonts/TT Norms Pro Thin Italic.woff") format("woff");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: normal;
  font-weight: 200;
  src: url("assets/fonts/TT Norms Pro ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: italic;
  font-weight: 200;
  src: url("assets/fonts/TT Norms Pro ExtraLight Italic.woff") format("woff");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: normal;
  font-weight: 300;
  src: url("assets/fonts/TT Norms Pro Light.woff") format("woff");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: italic;
  font-weight: 300;
  src: url("assets/fonts/TT Norms Pro Light Italic.woff") format("woff");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: normal;
  font-weight: 500;
  src: url("assets/fonts/TT Norms Pro Medium.woff") format("woff");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: italic;
  font-weight: 500;
  src: url("assets/fonts/TT Norms Pro Medium Italic.woff") format("woff");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: normal;
  font-weight: 600;
  src: url("assets/fonts/TT Norms Pro Bold.woff") format("woff");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: italic;
  font-weight: 600;
  src: url("assets/fonts/TT Norms Pro Bold Italic.woff") format("woff");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: normal;
  font-weight: 700;
  src: url("assets/fonts/TT Norms Pro ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: italic;
  font-weight: 700;
  src: url("assets/fonts/TT Norms Pro ExtraBold Italic.woff") format("woff");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: normal;
  font-weight: 800;
  src: url("assets/fonts/TT Norms Pro Black.woff") format("woff");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: italic;
  font-weight: 800;
  src: url("assets/fonts/TT Norms Pro Black Italic.woff") format("woff");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: normal;
  font-weight: 900;
  src: url("assets/fonts/TT Norms Pro ExtraBlack.woff") format("woff");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: italic;
  font-weight: 900;
  src: url("assets/fonts/TT Norms Pro ExtraBlack Italic.woff") format("woff");
}

@-webkit-keyframes zoomIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}